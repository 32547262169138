import MenubarItem from '../../application/navigation-wai/menubar-item';

//////////////////////////////
// MenubarLinks
//////////////////////////////

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/

// This has been amended (and converted to ES6) by Ledgard Jepson

class Menubar {
  constructor(domNode, viewportWidthThreshold) {
    let elementChildren,
        msgPrefix = 'Menubar constructor argument menubarNode ';

    // Check whether menubarNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }

    // Check whether menubarNode has descendant elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }

    // Check whether menubarNode has A elements
    let e = domNode.firstElementChild;
    let menubarItem;
    while (e) {
      menubarItem = e.firstElementChild;
      if (e && menubarItem && menubarItem.tagName !== 'A') {
        throw new Error(msgPrefix + 'has child elements are not A elements.');
      }
      e = e.nextElementSibling;
    }

    this.viewportWidthThreshold = viewportWidthThreshold;

    this.isMenubar = true;

    this.domNode = domNode;

    this.menubarItems = []; // See Menubar init method
    this.firstChars = []; // See Menubar init method

    this.firstItem = null; // See Menubar init method
    this.lastItem = null; // See Menubar init method

    this.hasFocus = false; // See MenubarItem handleFocus, handleBlur
    this.hasHover = false; // See Menubar handleMouseover, handleMouseout

    this.init();
  }


  /*
  *   @method Menubar.prototype.init
  *
  *   @desc
  *       Adds ARIA role to the menubar node
  *       Traverse menubar children for A elements to configure each A element as a ARIA menuitem
  *       and populate menuitems array. Initialize firstItem and lastItem properties.
  */
  init() {
    let menubarItem,
        childElement,
        menuElement,
        textContent,
        numItems;

    // Traverse the element children of menubarNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    let elem = this.domNode.firstElementChild;

    while (elem) {
      menuElement = elem.firstElementChild;

      if (elem && menuElement && menuElement.tagName === 'A') {
        menubarItem = new MenubarItem(menuElement, this);
        this.menubarItems.push(menubarItem);
        textContent = menuElement.textContent.trim();
        this.firstChars.push(textContent.substring(0, 1).toLowerCase());
      }

      elem = elem.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menubarItems.length;
    if (numItems > 0) {
      this.firstItem = this.menubarItems[ 0 ];
      this.lastItem = this.menubarItems[ numItems - 1 ];
    }
    this.firstItem.domNode.tabIndex = 0;
  };

  /* FOCUS MANAGEMENT METHODS */

  setFocusToItem(newItem) {
    let flag = false;
    let mbi;

    for (let i = 0; i < this.menubarItems.length; i++) {
      mbi = this.menubarItems[i];

      if (mbi.domNode.tabIndex == 0) {
        flag = mbi.domNode.getAttribute('aria-expanded') === 'true';
      }

      mbi.domNode.tabIndex = -1;
      if (mbi.popupMenu) {
        mbi.popupMenu.close();
      }
    }

    newItem.domNode.focus();
    newItem.domNode.tabIndex = 0;

    if (flag && newItem.popupMenu) {
      newItem.popupMenu.open();
    }
  };

  setFocusToFirstItem(flag) {
    this.setFocusToItem(this.firstItem);
  };

  setFocusToLastItem(flag) {
    this.setFocusToItem(this.lastItem);
  };

  setFocusToPreviousItem(currentItem) {
    let index, newItem;

    if (currentItem === this.firstItem) {
      newItem = this.lastItem;
    }
    else {
      index = this.menubarItems.indexOf(currentItem);
      newItem = this.menubarItems[ index - 1 ];
    }

    this.setFocusToItem(newItem);
  };

  setFocusToNextItem(currentItem) {
    let index, newItem;

    if (currentItem === this.lastItem) {
      newItem = this.firstItem;
    }
    else {
      index = this.menubarItems.indexOf(currentItem);
      newItem = this.menubarItems[ index + 1 ];
    }

    this.setFocusToItem(newItem);
  };

  setFocusByFirstCharacter(currentItem, char) {
    let start, index;
    char = char.toLowerCase();
    let flag = currentItem.domNode.getAttribute('aria-expanded') === 'true';

    // Get start index for search based on position of currentItem
    start = this.menubarItems.indexOf(currentItem) + 1;
    if (start === this.menubarItems.length) {
      start = 0;
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, char);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, char);
    }

    // If match was found...
    if (index > -1) {
      this.setFocusToItem(this.menubarItems[ index ]);
    }
  };

  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[ i ]) {
        return i;
      }
    }
    return -1;
  };

}

export default Menubar
