import Helpers from '../lib/helpers';
import Menubar from '../application/navigation-wai/menubar';

class Navigation {
  constructor({ selector, toggle, menubar }) {
    this.selector = document.querySelector(selector);
    this.toggle = document.querySelector(toggle);
    this.menubar = document.querySelector(menubar);

    // SLIDE: select elements to show/hide
    this.headerSearch = this.selector.parentElement.querySelector('.page-header__search');
    this.headerEnquiry = this.selector.parentElement.querySelector('.page-header__enquiry');
    this.headerNavUtility = this.selector.parentElement.querySelector('.nav__utility');

    this.visible = false;
    this.visibleElement = null;
    this.viewportWidthThreshold;
    this.vw; // Viewport Width
    this.touchDevice = false;
    this.navigationBreakpoint = 1300; // px value to change header layout
    this.navSlideHideTimer;

    if (!this.selector) { return null; }
    if (!this.toggle) { return null; }
    if (!this.menubar) { return null; }

    // Initiate burger menu
    this.toggle.addEventListener('click', (e) => {
      if(this.visible) {
        this._hide();
        this._toggleHidden();
      } else {
        this._show();
        this._toggleHidden();
      }
      e.preventDefault();
    });

    // Setup drop down menu
    this._toggleLevel1SubNavs();
    this._toggleLevel2SubNavs();

    // Setup mouseevents on
    this._mouseEvents();

    // Run WAI ARIA nav pattern functions
    new Menubar(
      document.getElementById('menubar'),
      this.viewportWidthThreshold
    );

    // Run viewport checker immediately
    this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

    // immediately run the width checker on initial page load
    // to make sure our initial state is correct
    this._viewportWidthChecker();

    // if viewport is resized to greater than 1080 (navigationBreakpoint) hide the nav
    window.addEventListener('resize', () => {
      this.vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
      // constantly run the VW checker as we resize the window
      this._viewportWidthChecker();
    }, { passive: true }); // set event listener to passive as we're not changing the default event behaviour of resize

    document.addEventListener('touchstart', this.touchDeviceDetected);
  }

  _toggleLevel1SubNavs() {
    Helpers.forEach(document.querySelectorAll('.nav-li-level1 > .js-subnav-toggle'), (el, _) => {
      el.setAttribute('aria-expanded', 'false');

      el.addEventListener('click', () => {
        if (el.getAttribute('aria-expanded') == 'true') {
          // console.log("Level 1 toggle button aria-expanded is true, hide the subnav")
          this._hideSubNav(el);
          this.visibleElement = null;

        } else {
          // console.log("Level 1 toggle button aria-expanded=false, show the subnav")
          if (this.visibleElement != null && this.visibleElement !== el){
            this._toggleOtherVisibleElement(this.visibleElement);
          }
          this._showSubNav(el);
          this.visibleElement = el;
        }
      });

    });
  }

  _toggleLevel2SubNavs() {
    Helpers.forEach(document.querySelectorAll('.nav-li-level2 > .js-subnav-toggle'), (el, _) => {
      el.setAttribute('aria-expanded', 'false');

      el.addEventListener('click', (e) => {
        if (el.getAttribute('aria-expanded') !== 'false') {
          // hide
          el.setAttribute('aria-expanded', 'false');
          if (el.parentNode.querySelector('a')) {
            el.parentNode.querySelector('a').setAttribute('aria-expanded', 'false');
          }
        } else {
          // show
          el.setAttribute('aria-expanded', 'true');
          if (el.parentNode.querySelector('a')) {
            el.parentNode.querySelector('a').setAttribute('aria-expanded', 'true');
          }
        }

        e.preventDefault();
      });
    });
  }

  _toggleOtherVisibleElement(visibleElement) {
    let vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (vw >= this.navigationBreakpoint) {
      this._hideSubNav(visibleElement);
    }
  }

  _showSubNav(el) {
    el.setAttribute('aria-expanded', 'true');
    el.parentNode.querySelector('.nav-ul-level2').setAttribute('data-state', 'active');
    el.parentNode.querySelector('a').setAttribute('aria-expanded', 'true');
    el.parentNode.setAttribute('data-state', 'active');
  };

  _hideSubNav(el) {
    el.setAttribute('aria-expanded', 'false');
    if (el.parentNode.querySelector('.nav-ul-level2')) {
      el.parentNode.querySelector('.nav-ul-level2').setAttribute('data-state', 'inactive');
    }
    el.parentNode.setAttribute('data-state', 'inactive');
    el.parentNode.querySelector('a').setAttribute('aria-expanded', 'false');
    el.parentNode.blur();
  };


  // Check width of the viewport
  /////////////////////////////////////////////////
  _viewportWidthChecker(){
    // if viewport is greater than the navigationBreakpoint
    if(this.vw >= this.navigationBreakpoint) {
      if (this.viewportWidthThreshold !== 'higher') {
        this.viewportWidthThreshold = 'higher';
        this._desktopToggleSwitch();
      }
    } else {
      if (this.viewportWidthThreshold !== 'lower') {
        this.viewportWidthThreshold = 'lower';
        this._desktopToggleSwitch();
      }
    }
  };

  // Switch to decide whether to show or hide
  /////////////////////////////////////////////////
  _desktopToggleSwitch(){
    if (this.viewportWidthThreshold == 'higher') {
      // functions to run if above the BP go here
      if (!document.body.classList.contains('has-sliding-nav')) {
        // if not a sliding nav
        this.menubar.hidden = false;
        this._hide();
      } else {
        // Is a sliding nav
        this._hide();
        this._showDesktop();
      }
    } else {
      // functions to run if below the BP go here
      if (!document.body.classList.contains('has-sliding-nav')) {
        // if not a sliding nv
        this.menubar.hidden = true;
      } else {
        this._showMobile();
      }

    }
  };


  ////////////////////////////////
  // HANDLE TOUCH DEVICES
  ////////////////////////////////

  // Detect if a touch device
  touchDeviceDetected(event) {
    this.touchDevice = true;

    Helpers.forEach(document.querySelectorAll('.nav-li-level1 > .js-subnav-toggle'), (el, _) => {
      // Allow touch devices to open level 2 subnavs
      el.style.pointerEvents ='auto';
    });

    document.addEventListener('touchstart', this.touchDeviceFunctions, { passive: true });
    document.removeEventListener('touchstart', this.touchDeviceDetected);
  }

  // Detect if anything other than the nav was touched
  touchDeviceFunctions(event) {
    // If el doesn't have a parent of #menubar then stop here
    if (!event.target.closest('#menubar')) {
      // console.log("something was touched other than nav");
      this.removeFocusAllToggles();
    }
  }

  removeFocusAllToggles() {
    if (this.viewportWidthThreshold == 'higher') {
      Helpers.forEach(document.querySelectorAll('.nav-li-level1 > .js-subnav-toggle'), (el, _) => {
        el.setAttribute('aria-expanded', 'false')
      });

      Helpers.forEach(document.querySelectorAll('.nav-li-level1 > a'), (el, _) => {
        el.setAttribute('aria-expanded', 'false');
      });
    }
  }


  _showMobile() {
    if(this.selector.getAttribute('data-state') === 'open') {
      // Nav was open, add back overlay

      // Create overlay
      let overlay = document.createElement('div');
      overlay.classList.add('overlay', 'nav__overlay');
      overlay.classList.add('is-visible');
      overlay.setAttribute('data-js', 'overlay');
      document.body.appendChild(overlay);
      // Add event listener to the overlay
      overlay.addEventListener('click', e => this._hide(e));
    }
  }

  _showDesktop() {
    document.querySelector('.page-header').setAttribute('data-state', 'active');
    this.menubar.hidden = false;
    // Show the other nav items to assistive tech
    this.headerSearch.removeAttribute('style');
    if (this.headerEnquiry) {
      this.headerEnquiry.removeAttribute('style');
    }
    if (this.headerNavUtility) {
      this.headerNavUtility.removeAttribute('style');
    }
    let overlay = document.querySelector('[data-js="overlay"].nav__overlay');
    if(overlay) { overlay.parentNode.removeChild(overlay); }
  }


  /**
   * Show the menu
   */
  _show() {
    // TODO: remove unnecessary targetting of attributes and data-states
    this.selector.setAttribute('data-state', 'open');
    this.toggle.setAttribute('aria-expanded', 'true');
    document.querySelector('html').classList.add('has-primary-nav');
    document.querySelector('.page-header').setAttribute('data-state', 'active');
    this.visible = true;

    // SLIDE: If this is a slide-in nav
    if (document.body.classList.contains('has-sliding-nav')) {
      // document.querySelector('.nav__slide-in').setAttribute('data-state', 'active');
      // document.querySelector('.nav__slide-in').setAttribute('aria-hidden', 'false');

      // Show the menubar to assistive tech
      clearTimeout(this.navSlideHideTimer);
      this.menubar.hidden = false;

      // Show the other nav items to assistive tech
      this.headerSearch.style.display = 'block';
      if(this.headerEnquiry){
        this.headerEnquiry.style.display = 'block';
      }
      if(this.headerNavUtility){
        this.headerNavUtility.style.display = 'block';
      }

      // create overlay
      let overlay = document.createElement('div');
      overlay.classList.add('overlay');
      overlay.classList.add('nav__overlay');
      overlay.classList.add('is-visible');
      overlay.setAttribute('data-js', 'overlay');
      document.body.appendChild(overlay);
      // add event listener to the overlay
      overlay.addEventListener('click', e => this._hide(e));
    }

  };

  /**
   * Hide the menu
   */
  _hide() {
    // SLIDE: remove unnecessary targetting of attributes and data-states
    this.selector.setAttribute('data-state', 'closed');
    this.toggle.setAttribute('aria-expanded', 'false');
    document.querySelector('html').classList.remove('has-primary-nav');
    document.querySelector('.page-header').setAttribute('data-state', '');
    this.visible = false;

    // If this is a slide-in nav
    if (document.body.classList.contains('has-sliding-nav')) {

      let overlay = document.querySelector('[data-js="overlay"].nav__overlay');
      if(overlay) {overlay.parentNode.removeChild(overlay);}

      if (this.viewportWidthThreshold !== 'higher') {
        // Hide the menu after a while
        this.navSlideHideTimer = setTimeout(() => {
          this.menubar.hidden = true;
          this.headerSearch.style.display = 'none';
          if(this.headerEnquiry){
            this.headerEnquiry.style.display = 'none';
          }
          if(this.headerNavUtility){
            this.headerNavUtility.style.display = 'none';
          }
        }, 500);
      }
    }
  };


  // Toggle the hidden attribute on menu UL
  _toggleHidden() {
    if (!document.body.classList.contains('has-sliding-nav')) {
      this.menubar.hidden = !this.menubar.hidden;
    }
    this.menubar.setAttribute('data-state', this.menubar.getAttribute('data-state') === 'open' ? 'closed' : 'open');
  };


  // Handle mouseEnter on Leve1 1 <li>s
  _handleMouseEnter(e) {
    if (this.viewportWidthThreshold == 'higher') {
      let topLevelLink = e.target.firstElementChild;
      topLevelLink.setAttribute('aria-expanded', 'true');
      // if not touch device
      if(!this.touchDevice) {
        let topLevelToggle = e.target.lastElementChild;
        if (topLevelToggle.classList.contains('js-subnav-toggle')) {
          topLevelToggle.setAttribute('aria-expanded', 'true');
        }
      }
    }
  };

  // Handle mouseLeave on Leve1 1 <li>s
  _handleMouseLeave(e) {
    if (this.viewportWidthThreshold == 'higher') {
      let topLevelLink = e.target.firstElementChild;
      topLevelLink.setAttribute('aria-expanded', 'false');

      let topLevelToggle = e.target.lastElementChild;
      if (topLevelToggle.classList.contains('js-subnav-toggle')) {
        topLevelToggle.setAttribute('aria-expanded', 'false');
      }
    }
  };

  // Attach mouse events
  _mouseEvents() {
    Helpers.forEach(document.querySelectorAll('.nav-li-level1'), (el) => {
      el.addEventListener('mouseenter', event => this._handleMouseEnter(event), false);
      el.addEventListener('mouseleave', event => this._handleMouseLeave(event), false);
    });
  };

}

export default Navigation
