import Helpers from '../../lib/helpers';
import PopupMenu from '../../application/navigation-wai/popup-menu';

//////////////////////////////
// MenubarItemLinks
//////////////////////////////

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/

// This has been amended (and converted to ES6) by Ledgard Jepson

class MenubarItem{
  constructor(domNode, menuObj) {

    this.domNode = domNode;
    this.menu = menuObj;
    this.viewportWidthThreshold = menuObj.viewportWidthThreshold;
    this.popupMenu = false;

    this.hasFocus = false;
    this.hasHover = false;

    this.isMenubarItem = true;

    this.keyCode = Object.freeze({
      'TAB': 9,
      'RETURN': 13,
      'ESC': 27,
      'SPACE': 32,
      'PAGEUP': 33,
      'PAGEDOWN': 34,
      'END': 35,
      'HOME': 36,
      'LEFT': 37,
      'UP': 38,
      'RIGHT': 39,
      'DOWN': 40
    });

    this.domNode.tabIndex = -1;
    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
    this.domNode.addEventListener('focus', this.handleFocus.bind(this));
    this.domNode.addEventListener('blur', this.handleBlur.bind(this));
    this.domNode.addEventListener('mouseover', this.handleMouseover.bind(this));
    this.domNode.addEventListener('mouseout', this.handleMouseout.bind(this));

    let nextElement = this.domNode.nextElementSibling;

    if (nextElement && nextElement.tagName === 'UL') {
      this.popupMenu = new PopupMenu(nextElement, this);
    }
  };

  handleKeydown(event) {
    let tgt = event.currentTarget,
        char = event.key,
        flag = false,
        clickEvent;

    let isPrintableCharacter = (str) => {
      return str.length === 1 && str.match(/\S/);
    }

    switch (event.keyCode) {
      case this.keyCode.SPACE:
      case this.keyCode.RETURN:
      case this.keyCode.DOWN:
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToFirstItem();
          flag = true;
        }
        break;

      case this.keyCode.LEFT:
        this.menu.setFocusToPreviousItem(this);
        flag = true;
        break;

      case this.keyCode.RIGHT:
        this.menu.setFocusToNextItem(this);
        flag = true;
        break;

      case this.keyCode.UP:
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToLastItem();
          flag = true;
        }
        break;

      case this.keyCode.HOME:
      case this.keyCode.PAGEUP:
        this.menu.setFocusToFirstItem();
        flag = true;
        break;

      case this.keyCode.END:
      case this.keyCode.PAGEDOWN:
        this.menu.setFocusToLastItem();
        flag = true;
        break;

      case this.keyCode.TAB:
        if (this.popupMenu) {
          this.popupMenu.close(true);
        }
        break;

      case this.keyCode.ESC:
        this.popupMenu.close(true);
        break;

      default:
        if (isPrintableCharacter(char)) {
          this.menu.setFocusByFirstCharacter(this, char);
          flag = true;
        }
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  setExpanded(value) {
    let thisParent = this.domNode.parentElement;
    let thisToggle = Helpers.childrenMatches(thisParent, '.js-subnav-toggle');

    if (value) {
      // console.log("Set aria-expanded='true' on level 1 A tag")
      this.domNode.setAttribute('aria-expanded', 'true');
      // TODO: do i have to do a foreach here? / select item without sending it to array
      Helpers.forEach(thisToggle, (el) => {
        // console.log("This toggle was made aria-expanded=true", el);
        el.setAttribute('aria-expanded', 'true');
      });
    }
    else {
      // console.log("Set aria-expanded='false' on level 1 A tag")
      this.domNode.setAttribute('aria-expanded', 'false');
      // TODO: do i have to do a foreach here? / select item without sending it to array
      Helpers.forEach(thisToggle, (el, _) => {
        // console.log("make toggle false, 1");
        el.setAttribute('aria-expanded', 'false');
      });
    }
  };

  handleFocus(event) {
    this.menu.hasFocus = true;
  };

  handleBlur(event) {
    this.menu.hasFocus = false;
  };

  handleMouseover(event) {
    if ((this.viewportWidthThreshold == 'higher') && (this.popupMenu !== false)) {
      // Functions to run if above the BP go here
      this.hasHover = true;
      this.popupMenu.open();
    } else {
      // functions to run if below the BP go here
      // console.log("below the nav bp, don't add mouseover events to level 1 <a> tag items")
    }
  };

  handleMouseout(event) {
    if ((this.viewportWidthThreshold == 'higher') && (this.popupMenu !== false)) {
      // Functions to run if above the BP go here
      //  console.log("Above the nav bp, run mouseout events")
      this.hasHover = false;
      setTimeout(this.popupMenu.close.bind(this.popupMenu, false), 300);
    } else {
      // functions to run if below the BP go here
      // console.log("below the nav bp, don't add mouseout events to level 1 <a> tag items")
    }

  };

}

export default MenubarItem
