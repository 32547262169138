import Helpers from '../../lib/helpers';
import PopupMenu from '../../application/navigation-wai/popup-menu';

//////////////////////////////
// PopupMenuItemLinks
//////////////////////////////

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/

// This has been amended (and converted to ES6) by Ledgard Jepson

class MenuItem {
  constructor(domNode, menuObj) {
    // if (typeof popupObj !== 'object') {
    //   popupObj = false;
    // } <-----  these 3 lines commented out as seem pointless?

    this.domNode = domNode;
    this.menu = menuObj;
    this.popupMenu = false;
    this.isMenubarItem = false;

    this.keyCode = Object.freeze({
      'TAB': 9,
      'RETURN': 13,
      'ESC': 27,
      'SPACE': 32,
      'PAGEUP': 33,
      'PAGEDOWN': 34,
      'END': 35,
      'HOME': 36,
      'LEFT': 37,
      'UP': 38,
      'RIGHT': 39,
      'DOWN': 40
    });

    this.domNode.tabIndex = -1;

    this.domNode.addEventListener('keydown', this.handleKeydown.bind(this));
    this.domNode.addEventListener('click', this.handleClick.bind(this));
    this.domNode.addEventListener('focus', this.handleFocus.bind(this));
    this.domNode.addEventListener('blur', this.handleBlur.bind(this));
    this.domNode.addEventListener('mouseover', this.handleMouseover.bind(this));
    this.domNode.addEventListener('mouseout', this.handleMouseout.bind(this));

    // Initialize flyout menu

    let nextElement = this.domNode.nextElementSibling;

    if (nextElement && nextElement.tagName === 'UL') {
      this.popupMenu = new PopupMenu(nextElement, this);
    }

  };

  isExpanded() {
    return this.domNode.getAttribute('aria-expanded') === 'true';
  };

  /* EVENT HANDLERS */

  handleKeydown(event) {
    let tgt  = event.currentTarget;
    let char = event.key;
    let flag = false;
    let clickEvent;

    let isPrintableCharacter = (str) => {
      return str.length === 1 && str.match(/\S/);
    }

    switch (event.keyCode) {
      case this.keyCode.SPACE:
      case this.keyCode.RETURN:
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToFirstItem();
        }
        else {

          // Create simulated mouse event to mimic the behavior of ATs
          // and let the event handler handleClick do the housekeeping.
          try {
            clickEvent = new MouseEvent('click', {
              'view': window,
              'bubbles': true,
              'cancelable': true
            });
          }
          catch (err) {
            if (document.createEvent) {
              // DOM Level 3 for IE 9+
              clickEvent = document.createEvent('MouseEvents');
              clickEvent.initEvent('click', true, true);
            }
          }
          tgt.dispatchEvent(clickEvent);
        }

        flag = true;
        break;

      case this.keyCode.UP:
        this.menu.setFocusToPreviousItem(this);
        flag = true;
        break;

      case this.keyCode.DOWN:
        this.menu.setFocusToNextItem(this);
        flag = true;
        break;

      case this.keyCode.LEFT:
        this.menu.setFocusToController('previous', true);
        this.menu.close(true);
        flag = true;
        break;

      case this.keyCode.RIGHT:
        if (this.popupMenu) {
          this.popupMenu.open();
          this.popupMenu.setFocusToFirstItem();
        }
        else {
          this.menu.setFocusToController('next', true);
          this.menu.close(true);
        }
        flag = true;
        break;

      case this.keyCode.HOME:
      case this.keyCode.PAGEUP:
        this.menu.setFocusToFirstItem();
        flag = true;
        break;

      case this.keyCode.END:
      case this.keyCode.PAGEDOWN:
        this.menu.setFocusToLastItem();
        flag = true;
        break;

      case this.keyCode.ESC:
        this.menu.setFocusToController();
        this.menu.close(true);
        flag = true;
        break;

      case this.keyCode.TAB:
        this.menu.setFocusToController();
        break;

      default:
        if (isPrintableCharacter(char)) {
          this.menu.setFocusByFirstCharacter(this, char);
          flag = true;
        }
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  setExpanded(value) {
    let thisParent = this.domNode.parentElement;
    let thisToggle = Helpers.childrenMatches(thisParent, '.js-subnav-toggle');

    if (value) {
      // console.log("Set aria-expanded=true on level 2 a tag")
      this.domNode.setAttribute('aria-expanded', 'true');
      // TODO: do i have to do a foreach here?
      Helpers.forEach(thisToggle, (el, _) => {
        el.setAttribute('aria-expanded', 'true');
      });
    }
    else {
      this.domNode.setAttribute('aria-expanded', 'false');
      // console.log("Set aria-expanded=false on level 2 a tag")
      // TODO: do i have to do a foreach here?
      Helpers.forEach(thisToggle, (el, _) => {
        el.setAttribute('aria-expanded', 'false');
      });
    }
  };

  handleClick(event) {
    // Stop collapsing of subnav items into their parent on click
    // this.menu.setFocusToController();
    // this.menu.close(true);
  };

  handleFocus(event) {
    this.menu.hasFocus = true;
  };

  handleBlur(event) {
    this.menu.hasFocus = false;
    setTimeout(this.menu.close.bind(this.menu, false), 300);
  };

  handleMouseover(event) {
    // Block all mouseover event functions from firing
    // TODO: Remove this commented out code?

    // this.menu.hasHover = true;
    // this.menu.open();
    // if (this.popupMenu) {
    //   this.popupMenu.hasHover = true;
    //   this.popupMenu.open();
    // }
  };

  handleMouseout(event) {
    // Block all mouseout event functions from firing
    // TODO: Remove this commented out code?

    // if (this.popupMenu) {
    //   this.popupMenu.hasHover = false;
    //   this.popupMenu.close(true);
    // }

    // this.menu.hasHover = false;
    // setTimeout(this.menu.close.bind(this.menu, false), 300);
  };

}

export default MenuItem
