import Helpers from '../lib/helpers';

class Search {
  constructor({ searchContainerSelector, searchButtonsSelector, searchBarSelector }) {
    this.searchContainerSelector = searchContainerSelector;
    this.searchButtonsSelector = searchButtonsSelector;
    this.searchBarSelector = searchBarSelector;

    if (!this.searchContainerSelector) { return null; }
    if (!this.searchButtonsSelector) { return null; }
    if (!this.searchBarSelector) { return null; }

    // Show / hide the search bar
    let searchContainer = document.querySelector(this.searchContainerSelector);
    let searchButtons = document.querySelectorAll(this.searchButtonsSelector);
    let searchBar = document.querySelector(this.searchBarSelector);

    if (!searchContainer) { return null; }
    if (!searchButtons) { return null; }

    Helpers.forEach(searchButtons, (el, _i) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (searchBar) {
          if (searchBar.getAttribute('data-visible') != null) {
            searchBar.removeAttribute('data-visible');
            searchContainer.setAttribute('aria-expanded', 'false');
            el.removeAttribute('data-visible');
          }
          else {
            searchBar.setAttribute('data-visible', '');
            el.setAttribute('data-visible', '');
            searchContainer.setAttribute('aria-expanded', 'true');
            document.getElementById("q").focus();
          }
        }
      })
    });
  }
}

export default Search
