/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.

const images = require.context('../../images/application', true)
const imagePath = (name) => images(name, true)

import Rails from 'rails-ujs';
import modernizr from 'modernizr';
import lazySizes from 'lazysizes';
if (!('object-fit' in document.createElement('a').style)) {
  require('lazysizes/plugins/object-fit/ls.object-fit');
}

import Helpers from '../../javascripts/lib/helpers';
import CookieDisclaimer from '../../javascripts/lib/cookie-disclaimer';

import AddUserAgentClasses from '../../javascripts/application/add-user-agent-classes';
import Navigation from '../../javascripts/application/navigation';
import Search from '../../javascripts/application/search';
import StickyNav from '../../javascripts/application/sticky-nav';
import HeroSlider from '../../javascripts/application/hero-slider';
import GallerySlider from '../../javascripts/application/gallery-slider';
import ShareBar from '../../javascripts/application/share-bar';
import EqualHeightCells from '../../javascripts/application/equal-height-cells';
import Modals from '../../javascripts/application/modals';
import TableFade from '../../javascripts/application/table-fade';
import FakeUploadInput from '../../javascripts/application/fake-upload-input';
import SocialFeed from '../../javascripts/application/social-feed';
import HorizontalScroller from '../../javascripts/application/horizontal-scroller';

import '../../stylesheets/application/application';

const companyName = 'COMPANY NAME';

Rails.start();

Helpers.ready(() => {
  new AddUserAgentClasses();
  new Navigation({
    selector: '[data-js="primary-navigation"]',
    toggle: '[data-js="menu-toggle"]',
    menubar: '#menubar'
  });
  new Search({
    searchContainerSelector: '[data-search-container]',
    searchButtonsSelector: '[data-search]',
    searchBarSelector: '[data-search-box]',
  });
  new StickyNav({
    header: '.page-header',
    primaryNav: '.nav__primary',
    main: 'main.page-content',
    body: 'body',
  });
  new HeroSlider({
    selector: '[data-js="hero-slides"]'
  });
  new GallerySlider({
    selector: '[data-js="gallery-slides"]'
  });
  new ShareBar({
    selector: '[data-share-url]',
    companyName: companyName
  });
  new EqualHeightCells({
    selector: '[data-grid-two-thirds].both-images',
    breakpoint: 768
  });
  new Modals();
  new TableFade({
    selector: 'data-js-table-scroll-fade'
  });
  new CookieDisclaimer();
  new FakeUploadInput();
  new SocialFeed({
    selector: '[data-js="social-content"]',
  });
  new HorizontalScroller({
    selector: '[data-horizontal-scroller]'
  });
});
