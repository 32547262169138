import Helpers from '../lib/helpers';

class Modal {
  constructor(args) {
    // Setup some config items that can be overwritten later

    this.THEBODY = document.querySelector('body');
    this.ROOT = document.documentElement;

    // Store our modal type, and some other data to do stuff with
    this.videoRatio; // <-- Never seems to be set... but does get compared against... ??

    let defaultConfig = {
          modalType: 'html',
          modalTitle: '',
          content: '',
          closeSelector: '[data-js="modal-close"]', // the selector that should close the overlay
          visibleClassName: 'is-visible', // class for visibility
          videoRatioClass: 'video__wrap--16x9' ,
          sharingPlatforms: ['facebook','twitter', 'linkedin', 'email', 'pinterest', 'googleplus' ],
          sharingUrl: ''
        }

    this.config = args ? this._extendDefaults(defaultConfig, args) : defaultConfig;

    this._show(this.config);
  }


  //////////////////////////////////////////////////////////////////////////////
  // Private Functions
  //////////////////////////////////////////////////////////////////////////////

  _displayInfo(modalBox, modalBody) {
    let modalContent = document.createElement('div');
    modalContent.classList.add('info__wrap');
    modalBox.classList.add('modal__box--info');
    modalContent.innerHTML += '<h1>' + this.config.content + '</h1>';
    // add it inside the modal body
    modalBody.appendChild(modalContent);
    modalBox.appendChild(modalBody);
  }

  _displayShare(modalBox, modalBody) {
    let modalContent = document.createElement('div');
    modalContent.classList.add('share__wrap');
    modalBox.classList.add('modal__box--share');

    // Split the description text (blank space seperated) into an array
    let textArray = this.config.content.split(" ");

    // Create a var to hold the newly formatted text
    let descriptionString = "",
        sharingUrl;

    if (this.config.sharingUrl != '') {
      sharingUrl = encodeURIComponent(this.config.sharingUrl);
    }

    // For each word in the array...
    helpers.forEach(textArray, (el) => {
      // Add the current split word plus space at the end into the new string
      descriptionString += el + "%20";
    });

    // Add Facebook link
    modalContent.innerHTML += '<h3>Share this on social media</h3>'

    helpers.forEach(this.config.sharingPlatforms, (platform) => {
      switch (platform) {
        case 'twitter':
          // Add Twitter link
          modalContent.innerHTML
            += '<div><a href="https://twitter.com/intent/tweet/?text='
            + descriptionString
            + '&amp;url='
            + sharingUrl
            + '" target="_blank" aria-label="Share on Twitter"><svg class="share__icon" role="presentation"><use xlink:href="#svg--twitter"></use></svg><p class="share__link">Twitter</p></a></div>';
          break;
        case 'facebook':
          // Add Facebook link
          modalContent.innerHTML
          += '<div><a href="https://facebook.com/sharer/sharer.php?u='
          + sharingUrl
          + '" target="_blank" aria-label="Share on FaceBook"><svg class="share__icon" role="presentation"><use xlink:href="#svg--facebook"></use></svg><p class="share__link">FaceBook</p></a></div>';
          break;
        case 'linkedin':
          // Add linkedin link
          modalContent.innerHTML
            += '<div><a href="https://www.linkedin.com/shareArticle?mini=true&amp;url='
            + sharingUrl
            + '&amp;title='
            + descriptionString
            + '&amp;summary='
            + descriptionString
            + '&amp;source='
            + sharingUrl
            + '" target="_blank" aria-label="Share on LinkedIn"><svg class="share__icon" role="presentation"><use xlink:href="#svg--linkedin"></use></svg><p class="share__link">LinkedIn</p></a></div>';
            break;
        case 'pinterest':
          // Add pinterest link
          modalContent.innerHTML
            += '<div><a href="https://pinterest.com/pin/create/button/?url='
            + sharingUrl
            + '&amp;media='
            + sharingUrl
            + '&amp;description='
            + descriptionString
            + '" target="_blank" aria-label="Share on Pinterest"><svg class="share__icon" role="presentation"><use xlink:href="#svg--pinterest"></use></svg><p class="share__link">Pinterest</p></a></div>';
          break;
        case 'googleplus':
          // Add google+ link
          modalContent.innerHTML
            += '<div><a href="https://plus.google.com/share?url='
            + sharingUrl
            + '" target="_blank" aria-label="Share on Google+"><svg class="share__icon" role="presentation"><use xlink:href="#svg--google"></use></svg><p class="share__link">Google+</p></a></div>';
          break;
        case 'email':
        default:
          // Add email link
          modalContent.innerHTML
          += '<div><a href=" mailto:?subject='
          + descriptionString
          + '&amp;body='
          + descriptionString
          + sharingUrl
          + '" target="_self" aria-label="Share on E-Mail"><svg class="share__icon" role="presentation"><use xlink:href="#svg--email"></use></svg><p class="share__link">E-Mail</p></a></div>';
          break;
      }
    })

    modalBody.appendChild(modalContent)
    modalBox.appendChild(modalBody);
  }

  _displayVideo(modalBox, modalBody) {
    let modalContent = document.createElement('div');
    modalContent.classList.add('video__wrap');
    modalBox.classList.add('modal__box--video');

    // check for the video aspect ratio
    if (this.videoRatio === "4x3") {
      modalContent.classList.add('video__wrap--4x3');
    } else if (this.videoRatio === "square") {
      modalContent.classList.add('video__wrap--square');
    } else if (this.videoRatio === "16x9") {
      modalContent.classList.add('video__wrap--16x9');
    } else {
      // fallback to 16:9 ratio
      modalContent.classList.add('video__wrap--16x9');
    }

    modalContent.innerHTML += '<iframe src="' + this.config.content+'" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

    // add it inside the modal body
    modalBody.appendChild(modalContent)

    // Append the HTMLDivElement to the modal box as a child
    modalBox.appendChild(modalBody);
  }

  _displayHtml(modalBox, modalBody) {
    let modalContent = document.createElement('div');
    modalContent.classList.add('html__wrap');
    modalBox.classList.add('modal__box--html');

    let insertText = '<h2 class="page__title">Injected Title from _displayHtml()</h2><p>This is some text injected via the _displayHtml() function inside modal.js</p>';

    modalContent.innerHTML += insertText;

    if (this.config.content.outerHTML == undefined){
      modalContent.innerHTML += this.config.content
    }
    else {
      modalContent.innerHTML += this.config.content.outerHTML;
    }
    // append the main modal body content to the modalBox
    modalBody.appendChild(modalContent);
    modalBox.appendChild(modalBody);
  }

  _displayScrape(modalBox, modalBody) {
    let modalContent = document.createElement('div');
    modalContent.classList.add('scrape__wrap');
    modalBox.classList.add('modal__box--scrape');

    if (this.config.content.outerHTML == undefined){
      modalContent.innerHTML += this.config.content
    }
    else {
      modalContent.innerHTML += this.config.content.outerHTML;
    }
    // append the main modal body content to the modalBox
    modalBody.appendChild(modalContent);
    modalBox.appendChild(modalBody);
  }

  _displayAjax(modalBox, modalBody) {
    let modalContent = document.createElement('div');
    modalContent.classList.add('ajax__wrap');
    modalBox.classList.add('modal__box--ajax');

    // run the ajax load function
    this._loadXMLDoc(modalContent, modalBox);

    // append the main modal body content to the modalBox
    modalBody.appendChild(modalContent)
    modalBox.appendChild(modalBody);
  }


  /**
   * Extend Defaults
   *
   * @param {Object} source
   * @param {Object} properties
   */
  _extendDefaults(source, properties) {
    let newSource = source;
    let property;

    for (property in properties) {
      newSource[property] = properties[property];
    }

    return newSource; // to set options var
  }

  /**
   * Hide the modal box and overlay
   */

  _hide() {
    // select the modal box and overlay
    let modalBox = document.querySelector('[data-js="modal-box"]');
    let overlay = document.querySelector('[data-js="overlay"]');

    // remove the modal box and overlay
    modalBox.parentNode.removeChild(modalBox);
    overlay.parentNode.removeChild(overlay);

    let hasModalClass = 'has-modal';
    let stopTouchClass = 'stop-touch';

    // remove classes from elements
    if (this.THEBODY.classList) {
      this.THEBODY.classList.remove(hasModalClass);
      this.THEBODY.classList.remove(stopTouchClass);
      this.ROOT.classList.remove(hasModalClass);
    } else {
      // var theClassName = THEBODY.getAttribute('class');
      // theClassName = theClassName.replace(/(\s|^)has-modal(\s|$)/, ' ');
      // THEBODY.setAttribute('class', theClassName);
      this.THEBODY.className = this.THEBODY.className.replace(new RegExp('(^|\\b)' + hasModalClass.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      this.THEBODY.className = this.THEBODY.className.replace(new RegExp('(^|\\b)' + stopTouchClass.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
      this.ROOT.className = this.ROOT.className.replace(new RegExp('(^|\\b)' + hasModalClass.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    // remove touchmove event listener from body
    this.THEBODY.removeEventListener('touchmove', this._handleTouch, { passive: false });

    // remove resize event listener from window
    window.removeEventListener('resize', this._watchResize, true);
  };

  /**
   * Handle AJAX requests
   * @param {HTMLElement} modalContent Content to display
   */

  _loadXMLDoc(modalContent, modalBox) {
    let xmlhttp = new XMLHttpRequest();

    // create a loader
    modalContent.innerHTML = '<div class="loader-pulsing"></div>';

    xmlhttp.onreadystatechange = () => {
      modalBox.classList.add('ajax-loading');

      if (xmlhttp.readyState == XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
        // if (xmlhttp.status == 200 || xmlhttp.status === 304) {\

        if (xmlhttp.status == 200) {
          // Success! Do stuff with data.
          // console.log(xmlhttp.responseText);
          modalBox.classList.remove('ajax-loading');
          modalBox.classList.add('ajax-loaded');

          modalContent.innerHTML = xmlhttp.responseText;
        }
        else {
          console.error(xmlhttp.status, xmlhttp.statusText );
        }
      }
    };

    xmlhttp.open("GET", this.config.content, true);
    xmlhttp.send();
  }

  /**
   * Show the modal box and overlay
   */

  _show() {
    // create elements for the modal box and the overlay
    let modalBox = document.createElement('div');
    let modalBody = document.createElement('div');
    let overlay = document.createElement('div');

    // add classes to each of the new elements
    if (modalBox.classList && overlay.classList) {
      modalBox.classList.add('modal');
      modalBody.classList.add('modal__body');
      overlay.classList.add('overlay');
      this.THEBODY.classList.add('has-modal');
      this.ROOT.classList.add('has-modal');
    } else {
      modalBox.className += ' ' + 'modal';
      modalBody.className += ' ' + 'modal__body';
      overlay.className += ' ' + 'overlay';
      this.THEBODY.className += ' ' + 'has-modal';
      this.ROOT.className += ' ' + 'has-modal';
    }

    // add some js-hooks to the data-attribute
    modalBox.setAttribute('data-js', 'modal-box');
    overlay.setAttribute('data-js', 'overlay');


    // create an empty element we can send into the modal header if there's no title attribute
    let title = '<p class="modal__title"></p>';
    // but if the button does have a modal title data attribute...
    // add the this.config.modalTitle inside the element
    if (this.config.modalTitle != "") {
      title = '<p class="modal__title">' + this.config.modalTitle + '</p>';
    };

    // append the header into the newly created modalBox div
    // modalBox.innerHTML += '<header class="modal__header">' + title + '<span class="modal__close" data-js="modal-close"><svg class="close__icon" role="presentation"><use xlink:href="#svg--close"></use></svg></span></header>';

    // append the close button into the modalBody
    // so it scrolls with the body content
    modalBody.innerHTML += '<header class="modal__header"><span class="modal__close" data-js="modal-close"><svg class="close__icon" role="presentation"><use xlink:href="#svg--close"></use></svg></span></header>';

    switch (this.config.modalType) {
      case "video":
        // if the modal type is video inject an iframe
        this._displayVideo(modalBox, modalBody);
        break;
      case "info":
        //if the modal type is info inject an H1
        this._displayInfo(modalBox, modalBody);
        break;
      case "share":
        // if the modal type is share...
        // form the sharingbuttons.io URL and inject it
        this._displayShare(modalBox, modalBody);
        break;
      case "ajax":
        this._displayAjax(modalBox, modalBody);
        break;
      case "scrape":
        this._displayScrape(modalBox, modalBody);
        break;
      case "html":
      default:
        this._displayHtml(modalBox, modalBody);
        break;
    }

    // Now add the overlay and modalBox to the end of the body
    ////////////////////////////////////////////////////////////////////////////
    document.body.appendChild(modalBox);
    document.body.appendChild(overlay);

    // add visible classes to each of the new elements
    if (modalBox.classList && overlay.classList) {
      modalBox.classList.add(this.config.visibleClassName);
      overlay.classList.add(this.config.visibleClassName);
    } else {
      modalBox.className += ' ' + this.config.visibleClassName;
      overlay.className += ' ' + this.config.visibleClassName;
    }

    // make sure the modal window is an even number of pixels tall...
    // So we dont end up with half-pixel rendering initially
    let currentHeight = modalBox.getBoundingClientRect().height;
    let evenPixelHeight = 2 * Math.ceil(currentHeight / 2);
    modalBox.style.minHeight = evenPixelHeight  + "px";

    // add event listeners to the close selector
    let closeButton = document.querySelectorAll(this.config.closeSelector);

    // attach the event listener to all instances of the selector
    Helpers.forEach(closeButton, (el, i) => {
      el.addEventListener('click', () => { this._hide() });
    });

    // add event listener to the overlay
    overlay.addEventListener('click', () => { this._hide() });

    // add event listener to the document
    // to remove min-height on resize
    window.addEventListener('resize', () => this._watchResize(), true);



    /////////////////////////////////////////////////////
    // IOS BODY SCROLL LOCKING
    /////////////////////////////////////////////////////

    // Currently the iOS scroll blocking works great for enquiry forms... but not for video or Gmap modals because they display in iframes which would also require an event listener adding to block touchmove, but that would affect interactivity

    // so instead intercept the click event early if we are using a touch device and just open the video / maps URL directly in a new window

    // stop touch events on the body
    this.THEBODY.addEventListener('touchmove', this._handleTouch, { passive: false });
    this.THEBODY.classList.add('stop-touch');

    // block scrolling
    modalBody.addEventListener('touchstart', (e) => {

      // console.log("modalBody touchstart");

      // set the position of first touch
      previousClientY = e.touches[0].clientY;

      // grab the modal body via the touched target
      let ModalElement = e.touches[0].target.closest(".modal__body");

      // add an event listener on touchmove
      // bind late by adding the touchmove after touchstart
      modalBody.addEventListener('touchmove', (e) => {

        // allow touchmove on modalBody
        e.stopPropagation()

        // log current Y position, so we can compare it with previous
        let currentClientY = e.touches[0].clientY;

        // if the scroll is at the top of the box
        if(ModalElement.scrollTop == 0 || ModalElement.scrollTop == undefined) {
          // console.log("at the top");

          // stop scroll up
          if (previousClientY < currentClientY) {
            // console.log("scrolling down... prevent this event");
            e.preventDefault();
          };
        };

        // if the scroll is at the end of the box
        if (ModalElement.clientHeight + ModalElement.scrollTop == ModalElement.scrollHeight) {
          // console.log("at the bottom");

          // stop scroll down
          if (previousClientY > currentClientY) {
            // console.log("scrolling up... prevent this event");
            e.preventDefault();
          };
        };
      }, { passive: false });
    }, { passive: false });


    // close the modal box when user hits `esc` key
    document.onkeydown = (evt) => {
      evt = evt || window.event;
      if (evt.keyCode == 27) {
        this._hide();
      }
    };
  };

  // remove inline min-height (half-pixel rendering fix) on resize of window
  //////////////////////////////////////////////////////////
  _watchResize() {
    let modalBox = document.querySelector('.modal');

    if (!modalBox) return;

    if (modalBox.style.removeProperty) {
      modalBox.style.removeProperty('min-height');
    } else {
      modalBox.style.removeAttribute('min-height');
    }
  };

  // Handle blocking touch scroll on elements
  //////////////////////////////////////////////////////////
  _handleTouch(e) {
    // console.log("touchmove scroll disabled on this element");
    e.preventDefault();
  }
}

export default Modal
