import Helpers from '../lib/helpers';

class HorizontalScroller {
  constructor({ selector }) {
    this.selector = selector;
    if (!this.selector) { return null; }

    // Select all scrollers
    let horizontalScrollers = document.querySelectorAll(this.selector);

    // Stop function if we have none on the page
    if(!horizontalScrollers.length > 0) {
      // console.log("horizontalScrollers = ", horizontalScrollers)
      return
    }

    Helpers.forEach(horizontalScrollers, (el, i) => {
      const slider = el;
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });

      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });

      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });

      slider.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; // *3 = scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        // console.log(walk);
      });
    });
  }
}

export default HorizontalScroller
