import Helpers from '../lib/helpers';

class AddUserAgentClasses {
  constructor() {
    /**
    * detect IE
    * and add a specific class to the body
    * function modified from this: http://stackoverflow.com/questions/19999388/check-if-user-is-using-ie-with-jquery
    */
    let ua = window.navigator.userAgent;
    let a = "not-ie";
    let b = "";
    let msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older
      a = "msie",
      b = "ie10";
    }

    let trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11
      a = "msie",
      b = "ie11";
    }

    let edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+)
      a = "edge";
      b = "ie"+parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    let body = document.querySelector('body');

    // other browser
    if (a != "") {
      Helpers.addClass(body, a);
    }

    if (b != "") {
      Helpers.addClass(body, b);
    }

    // Firefox
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      // Do Firefox-related activities
      Helpers.addClass(body, 'firefox');
    }
  }
}

export default AddUserAgentClasses
