import Helpers from '../lib/helpers';

class ShareBar {
  constructor({ selector, companyName }) {
    this.selector = selector;
    this.companyName = companyName;

    if (!this.selector) { return null; }
    if (!this.companyName) { return null; }

    Helpers.forEach (
      document.querySelectorAll(this.selector), (el, i) => {
        let shareurl = el.getAttribute('data-share-url');
        let sharecontent = el.getAttribute('data-share-content');
        let shareplatform = el.getAttribute('data-share-platform');
        let companyName = this.companyName;

        el.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          let descriptionString = "";

          if (sharecontent) {
            let textArray = sharecontent.split(" ");
            // For each word in the array...
            Helpers.forEach (
              textArray, (el, i) => {
                // Add the current split word plus space at the end into the new string
                descriptionString += el + "%20";
              }
            );
          } else {
            // Else use the fallback string
            descriptionString = companyName;
          };

          let url;

          // Form the Twitter sharing URL
          if (shareplatform == 'twitter' ) {
            // Form the URL here
            url = 'https://twitter.com/intent/tweet/?text='
                + descriptionString
                + '&amp;url='
                + shareurl;
          }

          // Form the Facebook sharing URL
          if (shareplatform == 'facebook' ) {
            // form the URL here
            url = 'https://facebook.com/sharer/sharer.php?u='
                + shareurl;
          }

          // Form the Linkedin sharing URL
          if (shareplatform == 'linkedin' ) {
            url = 'https://www.linkedin.com/shareArticle?mini=true&url='
                + shareurl
                + '&title='
                + descriptionString
                + '&summary='
                + descriptionString
                + '&source='
                + shareurl;
          }

          // Form the Pinterest sharing URL
          if (shareplatform == 'pinterest') {
            url = 'https://pinterest.com/pin/create/button/?urlhttp%3A%2F%2F='
                + shareurl
                + '&amp;media='
                + shareurl
                + '&amp;description='
                + descriptionString;
          }

          // open a new window and go to the formed URL
          window.open(url);
        });
      }
    );
  }
}

export default ShareBar
