class CookieDisclaimer {
  constructor() {
    this.config = {
      classForBody: "has-cookie-banner",
      name: "cookies_accepted",
      templateHtml: '<div class="wrapper__content-small"><p id="message"></p><button class="btn" id="close">Close</button></div>',
      message: 'Our website uses cookies to monitor traffic on our website and ensure that we can provide our customers with the best online experience possible. Please read our <a href="/cookies">cookie policy</a> to view more details on the cookies we use.'
    };

    this.run();
  }

  run() {
    this.readCookie(this.config.name) || (() => {
      let div = document.createElement("div");
      div.setAttribute("class", "cookie-banner");
      div.setAttribute("id", "cookie-banner");
      div.setAttribute("aria-role", "alert");
      div.setAttribute("aria-live", "assertive");
      div.innerHTML += this.config.templateHtml;
      document.body.insertBefore(div, document.body.firstChild);
      document.getElementById("message").innerHTML = this.config.message;
      document.body.classList ? document.body.classList.add(this.config.classForBody) : document.body.className += " " + this.config.classForBody;
      document.getElementById("close").onclick = () => {
        this.writeCookie(this.config.name, !0, 1800);
        let a = document.getElementById("cookie-banner");
        return a.parentNode.removeChild(a), document.body.classList ? document.body.classList.remove(this.config.classForBody) : document.body.className = document.body.className.replace(new RegExp("(^|\\b)" + this.config.classForBody.split(" ").join("|") + "(\\b|$)", "gi"), " "), !1
      }
    })();
  };

  writeCookie(name, value, days) {
    let expires = "";

    if (days) {
      let date = new Date;
      date.setTime(date.getTime() + 24 * days * 60 * 60 * 1e3);
      expires = "; expires=" + date.toGMTString();
    }

    document.cookie = name + "=" + value + expires + "; path=/"
  };

  readCookie(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }

      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }

    return null;
  };
}

export default CookieDisclaimer
