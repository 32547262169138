import MenuItem from '../../application/navigation-wai/menu-item';

//////////////////////////////
// PopupMenuLinks.js
//////////////////////////////

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/

// This has been amended (and converted to ES6) by Ledgard Jepson

class PopupMenu {
  constructor(domNode, controllerObj) {
    let msgPrefix = 'PopupMenu constructor argument domNode ';

    // Check whether domNode is a DOM element
    if (!domNode instanceof Element) {
      throw new TypeError(msgPrefix + 'is not a DOM Element.');
    }
    // Check whether domNode has child elements
    if (domNode.childElementCount === 0) {
      throw new Error(msgPrefix + 'has no element children.');
    }
    // Check whether domNode descendant elements have A elements
    let childElement = domNode.firstElementChild;
    let menuitem;
    while (childElement) {
      menuitem = childElement.firstElementChild;
      if (menuitem && menuitem === 'A') {
        throw new Error(msgPrefix + 'has descendant elements that are not A elements.');
      }
      childElement = childElement.nextElementSibling;
    }

    this.isMenubar = false;

    this.domNode    = domNode;
    this.controller = controllerObj;

    this.viewportWidthThreshold = controllerObj.viewportWidthThreshold;

    this.menuitems = []; // See PopupMenu init method
    this.firstChars = []; // See PopupMenu init method

    this.firstItem = null; // See PopupMenu init method
    this.lastItem = null; // See PopupMenu init method

    this.hasFocus = false; // See MenuItem handleFocus, handleBlur
    this.hasHover = false; // See PopupMenu handleMouseover, handleMouseout

    this.init();
  };

  /*
  *   @method PopupMenu.prototype.init
  *
  *   @desc
  *       Add domNode event listeners for mouseover and mouseout. Traverse
  *       domNode children to configure each menuitem and populate menuitems
  *       array. Initialize firstItem and lastItem properties.
  */
  init() {
    let childElement, menuElement, menuItem, textContent, numItems, label;

    // Configure the domNode itself

    this.domNode.addEventListener('mouseover', this.handleMouseover.bind(this));
    this.domNode.addEventListener('mouseout', this.handleMouseout.bind(this));

    // Traverse the element children of domNode: configure each with
    // menuitem role behavior and store reference in menuitems array.
    childElement = this.domNode.firstElementChild;

    while (childElement) {
      menuElement = childElement.firstElementChild;

      if (menuElement && menuElement.tagName === 'A') {
        menuItem = new MenuItem(menuElement, this);
        this.menuitems.push(menuItem);
        textContent = menuElement.textContent.trim();
        this.firstChars.push(textContent.substring(0, 1).toLowerCase());
      }
      childElement = childElement.nextElementSibling;
    }

    // Use populated menuitems array to initialize firstItem and lastItem.
    numItems = this.menuitems.length;
    if (numItems > 0) {
      this.firstItem = this.menuitems[ 0 ];
      this.lastItem = this.menuitems[ numItems - 1 ];
    }
  };

  /* EVENT HANDLERS */

  // TODO: these events run on hover of level 2 AND 3 items
  handleMouseover(event) {
    if (this.viewportWidthThreshold == 'higher') {
      this.hasHover = true;
    } else {
      // Functions to run if below the BP go here
      // console.log("below the nav bp, don't run mouseover events to level 2 & 3 <a> tag items")
    }
  };

  handleMouseout(event) {
    if (this.viewportWidthThreshold == 'higher') {
      // Functions to run if above the BP go here
      this.hasHover = false;
      // setTimeout(this.close.bind(this, false), 1);
    } else {
      // functions to run if below the BP go here
      // console.log("below the nav bp, don't run mouseover events to level 2 & 3 <a> tag items")
    }
  };


  /* FOCUS MANAGEMENT METHODS */

  setFocusToController(command, flag) {

    if (typeof command !== 'string') {
      command = '';
    }

    let setFocusToMenubarItem = (controller, close) => {
      while (controller) {
        if (controller.isMenubarItem) {
          controller.domNode.focus();
          return controller;
        }
        else {
          if (close) {
            controller.menu.close(true);
          }
          controller.hasFocus = false;
        }
        controller = controller.menu.controller;
      }
      return false;
    }

    if (command === '') {
      if (this.controller && this.controller.domNode) {
        this.controller.domNode.focus();
      }
      return;
    }

    if (!this.controller.isMenubarItem) {
      this.controller.domNode.focus();
      this.close();

      if (command === 'next') {
        let menubarItem = setFocusToMenubarItem(this.controller, false);
        if (menubarItem) {
          menubarItem.menu.setFocusToNextItem(menubarItem, flag);
        }
      }
    }
    else {
      if (command === 'previous') {
        this.controller.menu.setFocusToPreviousItem(this.controller, flag);
      }
      else if (command === 'next') {
        this.controller.menu.setFocusToNextItem(this.controller, flag);
      }
    }

  };

  setFocusToFirstItem() {
    this.firstItem.domNode.focus();
  };

  setFocusToLastItem() {
    this.lastItem.domNode.focus();
  };

  setFocusToPreviousItem(currentItem) {
    let index;

    if (currentItem === this.firstItem) {
      this.lastItem.domNode.focus();
    }
    else {
      index = this.menuitems.indexOf(currentItem);
      this.menuitems[ index - 1 ].domNode.focus();
    }
  };

  setFocusToNextItem(currentItem) {
    let index;

    if (currentItem === this.lastItem) {
      this.firstItem.domNode.focus();
    }
    else {
      index = this.menuitems.indexOf(currentItem);
      this.menuitems[ index + 1 ].domNode.focus();
    }
  };

  setFocusByFirstCharacter(currentItem, char) {
    let start, index;
    char = char.toLowerCase();

    // Get start index for search based on position of currentItem
    start = this.menuitems.indexOf(currentItem) + 1;
    if (start === this.menuitems.length) {
      start = 0;
    }

    // Check remaining slots in the menu
    index = this.getIndexFirstChars(start, char);

    // If not found in remaining slots, check from beginning
    if (index === -1) {
      index = this.getIndexFirstChars(0, char);
    }

    // If match was found...
    if (index > -1) {
      this.menuitems[ index ].domNode.focus();
    }
  };

  getIndexFirstChars(startIndex, char) {
    for (let i = startIndex; i < this.firstChars.length; i++) {
      if (char === this.firstChars[ i ]) {
        return i;
      }
    }
    return -1;
  };

  /* MENU DISPLAY METHODS */

  open() {
    // Get position and bounding rectangle of controller object's DOM node
    let rect = this.controller.domNode.getBoundingClientRect();

    // Set CSS properties
    if (!this.controller.isMenubarItem) {
      this.domNode.parentNode.style.position = 'relative';
      // this.domNode.style.display = 'block';
      // this.domNode.style.position = 'absolute';
      // this.domNode.style.left = rect.width + 'px';
      // this.domNode.style.zIndex = 100;
    }
    else {
      // this.domNode.style.display = 'block';
      // this.domNode.style.position = 'absolute';
      // this.domNode.style.top = (rect.height - 1) + 'px';
      // this.domNode.style.zIndex = 100;
    }

    this.controller.setExpanded(true);

  };

  close(force) {
    let controllerHasHover = this.controller.hasHover;
    let hasFocus = this.hasFocus;

    let mi;
    for (let i = 0; i < this.menuitems.length; i++) {
      mi = this.menuitems[i];
      if (mi.popupMenu) {
        hasFocus = hasFocus | mi.popupMenu.hasFocus;
      }
    }

    if (!this.controller.isMenubarItem) {
      controllerHasHover = false;
    }

    if (force || (!hasFocus && !this.hasHover && !controllerHasHover)) {
      // this.domNode.style.display = 'none';
      // this.domNode.style.zIndex = 0;
      this.controller.setExpanded(false);
    }
  };

}

export default PopupMenu
