class SocialFeed {
  constructor({
    selector,
    maxSectionDisplayWidth = 1300,
    maxWidgetDisplayWidth = 560,
    widgetRowBreakpoint = 768,
  }) {
    this.selector = selector;
    this.maxSectionDisplayWidth = maxSectionDisplayWidth;   // viewport width at which the FB widget will not resize beyond
    this.maxWidgetDisplayWidth = maxWidgetDisplayWidth;     // max width of FB widget
    this.widgetRowBreakpoint = widgetRowBreakpoint;         // viewport width at which widgets will all display on one row

    if (!this.selector) { return null; }
    if (!document.querySelector(this.selector)) {
      // element not present - nothing to do
      return null;
    }

    window.addEventListener('load', () => {
      this.assessViewportWidth();
    });

    window.addEventListener('resize', () => {
      this.assessViewportWidth();
    });
  }

  assessViewportWidth() {
    let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // Don't run above 1300px as widget section row is already at it's max-width
    if (viewportWidth <= this.maxSectionDisplayWidth) {
      setTimeout(() => {
        this.resizeFBPagePluginWidth();
      }, 300);
    }
  }

  resizeFBPagePluginWidth() {
    let fbPagePlugin = document.querySelector('.fb-page');
    let container = document.querySelector('.social-grid__facebook');

    // Get container box width
    let raw_container_width = container.getBoundingClientRect().width;
    let raw_container_height = container.getBoundingClientRect().height;
    let container_width = Math.round(raw_container_width) + 2;
    let container_height = Math.round(raw_container_height) + 2;

    if (!isNaN(container_width)) {
      // Set container width / height
      fbPagePlugin.setAttribute("data-height", container_height);
      fbPagePlugin.setAttribute("data-width", container_width);
      // Re-render the widget
      FB.XFBML.parse();
    }
  }
}

export default SocialFeed
